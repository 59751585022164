import React, { ReactNode, Fragment } from "react";
import { Script, HeadFC } from "gatsby";
import { queryResult } from "../data/graphql/query";
import { getGatsbyImageNode } from "../../utils/getGatsbyImageNode";

import LayoutLibrary from "@flyvet/flyvet-react-library/dist/src/containers/layouts/layout-topbar-dark";

import "../styles/variables.scss";

export type LayoutProps = {
  children: ReactNode;
};

export const Layout = ({ children }: any) => {
  const {
    companyName,
    city,
    logoTopbar,
    logoFooter,
    GA4,
    facebook,
    instagram,
    landingSubtitle,
  } = queryResult();

  return (
    <Fragment>
      {/* <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA4}`}
        strategy="off-main-thread"
        forward={[`gtag`]}
      />
      <Script
        id="gtag-config"
        strategy="off-main-thread"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          window.gtag = function gtag(){ window.dataLayer.push(arguments);}
          gtag('js', new Date()); 
          gtag('config', '${GA4}', { send_page_view: false })`,
        }}
      /> */}
      <LayoutLibrary
        logoTopbar={getGatsbyImageNode(
          logoTopbar,
          `Logo ${companyName}`,
          true,
          false,
          false
        )}
        isFilledLogoFooter={false}
        logoFooter={getGatsbyImageNode(
          logoFooter,
          `Logo ${companyName}`,
          true,
          true,
          true
        )}
        companyName={companyName}
        city={city}
        slogan={landingSubtitle}
        badge={{ type: "vet" }}
        facebook={facebook}
        instagram={instagram.user}
        linkedin=""
        navItems={[
          {
            label: "Home",
            path: "/",
          },
          {
            label: "Serviços",
            path: "/servicos",
          },
          {
            label: "Contato",
            path: "/contato",
          },
        ]}
      >
        {children}
      </LayoutLibrary>
    </Fragment>
  );
};

export default Layout;

export const Head: HeadFC<{}> = ({}) => (
  <>
    <link
      key="nunito"
      rel="preload"
      href="/src/styles/fonts/nunito-v26-latin-regular.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    ,
    <link
      key="titillium-web"
      rel="preload"
      href="/src/styles/fonts/titillium-web-v17-latin-regular.woff2"
      as="font"
      type="font/woff2"
      crossOrigin="anonymous"
    />
    ,
  </>
);
